// the idea of this is to reduce the code in index.jsx, you pass the project details and you have it
//but for now emains unused for the deal with the background Image, cannot send it to css, plus the letters contrast
// which depends on the background

import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import React from "react"
import Slide from "react-reveal/Slide";
import styles from './index.module.scss'
import PropTypes from 'prop-types'
import { SeeMoreButton } from '../seeMoreButton/seeMoreButton'

const ProjectCardMobile = ({ title, logoUrl, text, page, contrast, fotos, name, textButton }) => {
  const { fluid } = fotos.find(foto => foto.childImageSharp.fluid.src.indexOf(`foto-${name}-mobile`) > -1).childImageSharp
  return (
    <BackgroundImage className={styles.wrapper} fluid={fluid}>
      <Slide left>
        <div className={`${styles.text_container} ${contrast ? styles.contrast : ''}`}>
          <img src={logoUrl} />
          <p className={`${contrast ? styles.contrast : ''}`}>{text}</p>
          <SeeMoreButton text={textButton} sendTo={page} contrast={contrast} />
        </div>
      </Slide>
    </BackgroundImage>
  )
}

ProjectCardMobile.defaultProps = {
  contrast: false,
  textButton: undefined,
}


ProjectCardMobile.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  contrast: PropTypes.bool,
  fotos: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  textButton: PropTypes.string,
}

export default ProjectCardMobile
