import BackgroundImage from "gatsby-background-image"
import React from "react"
import Slide from "react-reveal/Slide"
import Layout from "../../Layout/Layout"
import ProjectCardMobile from "../../project-card-mobile/projectCardMobile"
import { SeeMoreButton } from "../../seeMoreButton/seeMoreButton"
import styles from "../../../styles/index.module.scss"
import tt from "../../../helpers/translation"

const MobileContent = ({
  seoData,
  resultUrls,
  fotos,
  homeBackground,
  rodiLogoUrl,
  nouCimsLogoUrlWhite,
  portAventuraLogo,
  oxfamLogo,
  lang,
}) => (
  <React.Fragment>
    <Layout
      leftContactUs
      lan={lang}
      location="home"
      seoData={seoData}
      urls={resultUrls}
    >
      <div>
        <BackgroundImage
          className={styles.first_image_container}
          fluid={homeBackground}
        >
          {/* <Slide left> */}
          <div className={styles.text_container}>
            <h1>{tt("Simplicity is the key to brilliance.", lang)}</h1>
            <p className={styles.subtitle}>
              {tt(
                "We are focused on building effective digital experiences for public and private organizations",
                lang
              )}
            </p>
            <SeeMoreButton
              text={tt("See our work", lang)}
              sendTo={tt("/our-work/", lang)}
            />
          </div>
          {/* </Slide> */}
        </BackgroundImage>
        <div className={styles.white_section_container}>
          <Slide left>
            <div className={styles.text_container}>
              <h2>{tt("We love innovation", lang)}</h2>
              <p className={styles.subtitle}>
                {tt(
                  "We provide innovative and creative technological solutions for our clients helping them to reach their goals",
                  lang
                )}
              </p>
              <SeeMoreButton
                text={tt("See our work", lang)}
                contrast
                sendTo={tt("/our-work/", lang)}
              />
            </div>
          </Slide>
        </div>
        <ProjectCardMobile
          text={tt(
            "E-commerce platform for a leading automotive repair chain.",
            lang
          )}
          title={"Rodi Motor Services"}
          logoUrl={rodiLogoUrl}
          page={tt("/our-work/rodi/", lang)}
          fotos={fotos}
          name={"rodi"}
          textButton={tt("See project", lang)}
        />
        <ProjectCardMobile
          text={tt(
            "The development of Port Aventura World’s new website is the result of our close-knit collaboration with McCann Worldgroup Barcelona.",
            lang
          )}
          title={"PortAventura World - Theme and leisure park"}
          logoUrl={portAventuraLogo}
          page={tt("/our-work/paw/", lang)}
          fotos={fotos}
          name={"portAventura"}
          textButton={tt("See project", lang)}
        />
        <ProjectCardMobile
          text={tt(
            "A project of digital transformation to implement an online solution to handle the organisational processes at Nous Cims.",
            lang
          )}
          title={"Nous Cims"}
          logoUrl={nouCimsLogoUrlWhite}
          page={tt("/our-work/nousCims/", lang)}
          fotos={fotos}
          name={"nousCims"}
          textButton={tt("See project", lang)}
        />
        <ProjectCardMobile
          text={tt(
            "Online toolkit to discover and propose solutions to the inequalities that occur in the world.",
            lang
          )}
          title={"Intermón Oxfam"}
          logoUrl={oxfamLogo}
          page={tt("/our-work/oxfam/", lang)}
          fotos={fotos}
          name={"intermon"}
          contrast
          textButton={tt("See project", lang)}
        />
      </div>
    </Layout>
  </React.Fragment>
)

export default MobileContent
