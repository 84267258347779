import Fullpage, {
  FullpageNavigation,
  FullpageSection,
  FullPageSections,
} from "@ap.cx/react-fullpage"
import React, { useRef } from "react"
import Slide from "react-reveal/Slide"
import ContactUs from "../../ContactUs/ContactUs"
import Footer from "../../footer/footer"
import Header from "../../header/header"
import ProjectCard from "../../project-card/projectCard"
import { SeeMoreButton } from "../../seeMoreButton/seeMoreButton"
import SeoComponent from "../../SeoComponent"
import styles from "../../../styles/index.module.scss"
import ArrowUp from "../../../assets/arrow-blue-up.svg"
import tt from "../../../helpers/translation"

const DesktopContent = ({
  seoData,
  resultUrls,
  fotos,
  thumbnail,
  rodiLogoUrl,
  nouCimsLogoUrlWhite,
  portAventuraLogo,
  oxfamLogo,
  lang,
}) => {
  const firstSection = useRef(null)
  const secondSection = useRef(null)
  const thirdSection = useRef(null)
  const fourthSection = useRef(null)
  const fifthSection = useRef(null)
  const sixthSection = useRef(null)
  const seventhSection = useRef(null)
  const eigthSection = useRef(null)
  const refsArray = [
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
    fifthSection,
    sixthSection,
    seventhSection,
    eigthSection,
  ]

  const scrollToSection = idx => {
    window.scrollTo({
      left: 0,
      top: refsArray[idx - 1].current.offsetTop,
      behavior: "smooth",
    })
  }
  return (
    <React.Fragment>
      <SeoComponent language={lang} {...seoData} />
      <Fullpage>
        <div
          className={styles.navigation}
          onClick={e => {
            e.persist()
            window.scrollTo({
              left: 0,
              top: e.screenY,
              behavior: "smooth",
            })
          }}
        >
          <FullpageNavigation />
        </div>
        <FullPageSections>
          <FullpageSection style={{ maxHeight: "100vh", position: "relative" }}>
            <div
              ref={firstSection}
              onClick={() => scrollToSection(2)}
              className={styles.section_container}
            >
              {/* {videoLoaded ? <Header className={styles.header} language={'en'} urls={resultUrls} /> : null} */}
              <Header
                className={styles.header}
                language={lang}
                urls={resultUrls}
              />
              <div className={styles.enter_from_left}>
                <div className={styles.text_container_desktop}>
                  <h1>{tt("Simplicity is the key to brilliance.", lang)}</h1>
                  <p className={styles.subtitle}>
                    {tt(
                      "We are focused on building effective digital experiences for public and private organizations",
                      lang
                    )}
                  </p>
                  <SeeMoreButton
                    text={tt("See our work", lang)}
                    sendTo={tt("/our-work/", lang)}
                  />
                </div>
              </div>
              <div className={styles.player_wrapper}>
                <video
                  className={styles.video}
                  poster={thumbnail}
                  muted={true}
                  autoPlay="autoplay"
                  loop
                  controls={false}
                >
                  <source
                    src="https://interficie-website.s3.amazonaws.com/video_web_normal.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </FullpageSection>
          <FullpageSection>
            <div
              ref={secondSection}
              className={`${styles.white_section_container} ${styles.section_container}`}
              onClick={() => scrollToSection(3)}
            >
              <Slide left>
                <div className={styles.text_container}>
                  <h2>{tt("We love innovation", lang)}</h2>
                  <p className={styles.subtitle}>
                    {tt(
                      "We provide innovative and creative technological solutions for our clients helping them to reach their goals",
                      lang
                    )}
                  </p>
                  <SeeMoreButton
                    contrast
                    text={tt("See our work", lang)}
                    sendTo={tt("/our-work/", lang)}
                  />
                </div>
              </Slide>
            </div>
          </FullpageSection>
          <FullpageSection>
            <div
              ref={thirdSection}
              onClick={() => scrollToSection(4)}
              className={styles.section_container}
            >
              <ProjectCard
                text={tt(
                  "E-commerce platform for a leading automotive repair chain.",
                  lang
                )}
                title={"Rodi Motor Services"}
                logoUrl={rodiLogoUrl}
                page={tt("/our-work/rodi/", lang)}
                fotos={fotos}
                name={"rodi"}
                textButton={tt("See project", lang)}
              />
            </div>
          </FullpageSection>
          <FullpageSection>
            <div
              ref={fourthSection}
              onClick={() => scrollToSection(5)}
              className={styles.section_container}
            >
              <ProjectCard
                text={tt(
                  "The development of Port Aventura World’s new website is the result of our close-knit collaboration with McCann Worldgroup Barcelona.",
                  lang
                )}
                title={"PortAventura World - Theme and leisure park"}
                logoUrl={portAventuraLogo}
                page={tt("/our-work/paw/", lang)}
                fotos={fotos}
                name={"portAventura"}
                contrast
                textButton={tt("See project", lang)}
              />
            </div>
          </FullpageSection>
          <FullpageSection>
            <div
              ref={fifthSection}
              onClick={() => scrollToSection(6)}
              className={styles.section_container}
            >
              <ProjectCard
                text={tt(
                  "A project of digital transformation to implement an online solution to handle the organisational processes at Nous Cims.",
                  lang
                )}
                title={"Nous Cims"}
                logoUrl={nouCimsLogoUrlWhite}
                page={tt("/our-work/nousCims/", lang)}
                fotos={fotos}
                name={"nousCims"}
                textButton={tt("See project", lang)}
              />
            </div>
          </FullpageSection>
          <FullpageSection>
            <div
              ref={sixthSection}
              onClick={() => scrollToSection(7)}
              className={styles.section_container}
            >
              <ProjectCard
                text={tt(
                  "Online toolkit to discover and propose solutions to the inequalities that occur in the world.",
                  lang
                )}
                title={"Intermón Oxfam"}
                logoUrl={oxfamLogo}
                page={tt("/our-work/oxfam/", lang)}
                fotos={fotos}
                name={"intermon"}
                contrast
                textButton={tt("See project", lang)}
              />
            </div>
          </FullpageSection>
          <FullpageSection>
            <div
              className={`${styles.half_blue_section_container} ${styles.section_container} `}
              ref={seventhSection}
              onClick={() => scrollToSection(8)}
            >
              <Slide left>
                <div className={styles.blue_text_container}>
                  <h2>{tt("Our services", lang)}</h2>
                  <p className={styles.subtitle}>
                    {tt(
                      "We help enterprises and organizations to build their digital products.",
                      lang
                    )}
                  </p>
                  <SeeMoreButton
                    text={tt("See our services", lang)}
                    sendTo={tt("/what-we-do/", lang)}
                  />
                </div>
              </Slide>
              <Slide right>
                <p className={styles.text}>
                  {tt(
                    "We like to meet our customers and have a very close vision to their core business. In this way we can offer an optimal technical approach and offer our added value in solutions that really suit their needs.",
                    lang
                  )}
                </p>
              </Slide>
            </div>
          </FullpageSection>
          <FullpageSection>
            <div
              className={`${styles.white_section_container} ${styles.section_container_last}`}
              ref={eigthSection}
            >
              <ContactUs
                title={tt("Contact us", lang)}
                subtitle={tt(
                  "Start your project, we are here to help you!",
                  lang
                )}
                route={tt("/contact-us/", lang)}
                lang={lang}
              />
              <div
                className={styles.go_to_top}
                onClick={() => window.scrollTo(0, firstSection.offsetTop)}
              >
                <ArrowUp />
                <p>{tt("Back to the top", lang)}</p>
              </div>
            </div>
            <div className={styles.footer_wrapper}>
              <Footer lang={lang} />
            </div>
          </FullpageSection>
        </FullPageSections>
      </Fullpage>
    </React.Fragment>
  )
}

export default DesktopContent
